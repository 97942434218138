<template>
<div class="home-container"  style="background: #fff">
    <CoolLightBox
        :items="testimonials"
        :index="index"
        effect="fade"
        @close="index = null">
    </CoolLightBox>
    <!-- ==============================================
    Cover
    =============================================== -->
    <div id="cover">
        <div class="inner">
            <div class="col span3of3 parallax" style="bottom: 0px;">
                <div id="logo">
                    <img src="/images/logo-meredith-white.svg" height="auto" width="35%" alt="Meredith Schlosser Beverly Hills">
                    <img src="/images/logo-bhhs.svg" height="auto" width="35%" alt="BHHS" class="mobile-only">
                </div>
            </div>
        </div>
        <div class="scrolldown"><a href="#home-listings" class="btn arrow bounce"></a></div>
        <div>
            <div id="company" class="relative" >
                <a href="https://www.bhhscalifornia.com/" target="_blank" rel="noopener noreferrer">
                <img src="/images/logo-bhhs.svg" alt="BHHS">
                </a>
            </div>
        </div>
    </div>
    <!--/ Cover -->
    <div id="home" >
        <vueper-slides
            fade
            parallax
            autoplay
            class="no-shadow"
            :bullets="false"
            :arrows="false"
            :duration="8000"
            :touchable="false"
            :fixed-height="'100%'">
            <vueper-slide
                v-for="(slide, i) in homeImages"
                :key="i"
                :image="slide"
                class="image_slide"
            >
            </vueper-slide>
        </vueper-slides>
    </div>
    <!-- Slideshow -->
    <flickity ref="flickity" id="slideshow" :options="flickityOptions">
        <div class="carousel-cell">
            <div class="testimonial">
                <p class="content">
                    "<span>She is focused, relentless (in an outstanding way)</span> and literally held my hand through the entire process, from beginning to end."
                </p>
                <p class="name">
                    - Lindsay T.
                </p>
            </div>
        </div>
        <div class="carousel-cell">
            <div class="testimonial">
                <p class="content">
                    "We would not have gotten the offer we received, <span>all cash and significantly over asking,</span> without her strong negotiation skills."
                </p>
                <p class="name">
                    - Ashley Starck
                </p>
            </div>
        </div>
        <div class="carousel-cell">
            <div class="testimonial">
            <p class="content">
                "She took care of all the stress of getting the house ready to show, positioning the best offer, <span>and ultimately getting a sale price OVER ASKING!</span>"
            </p>
            <p class="name">
                - G. Bray
            </p>
            </div>
        </div>
        <div class="carousel-cell">
                <div class="testimonial">
            <p class="content">
                "Your <span>instincts with regards to pricing and timing</span> of the sale were excellent."
            </p>
            <p class="name">
                - Mark & Julie Chin
            </p>
            </div>
        </div>
        <div class="carousel-cell">
                <div class="testimonial">
            <p class="content">
                "Meredith really has your <span>best interest in mind</span>. She takes it as a personal challenge to do her best."
            </p>
            <p class="name">
                - Dae Beck
            </p>
            </div>
        </div>
        <div class="carousel-cell">
                <div class="testimonial">
            <p class="content">
                "She has far and away <span>made this move across the country</span> easier than we ever thought possible."
            </p>
            <p class="name">
                - Lindsay Thelen
            </p>
            </div>
        </div>
        <div class="carousel-cell">
                <div class="testimonial">
            <p class="content">
                "I always knew I had a smart & relentless fighter going to <span>battle for me in negotiations.</span>"
            </p>
            <p class="name">
                - Nathan East Hayden
            </p>
            </div>
        </div>
        <div class="carousel-cell">
            <div class="testimonial">
            <p class="content">
                "She's <span>aggressive, a great communicator, prompt at returning calls</span>, helped us find the perfect designer to stage our home and did so much more"
            </p>
            <p class="name">
                - Ed & Marianne Burnside
            </p>
            </div>
        </div>
        <div class="carousel-cell">
                <div class="testimonial">
            <p class="content">
                "She has <span>vast experience with real estate</span>, and her people skills couldn't be better!"
            </p>
            <p class="name">
                - Renee Kaplan
            </p>
            </div>
        </div>
        <div class="carousel-cell">
            <div class="testimonial">
                <p class="content">
                    "From <span>beginning to end</span>, Meredith personally managed every aspect of selling our home, no matter how small, with complete focus and total <span>attention to detail.</span>"
                </p>
                <p class="name">
                    - Brian Wilson
                </p>
            </div>
        </div>
        <div class="carousel-cell">
            <div class="testimonial">
                <p class="content">
                    "She has been on <span>both the "buy" and "sell" side for us</span> and in each instance has been stellar."
                </p>
                <p class="name">
                    - Mark Wapnick
                </p>
            </div>
        </div>
        <div class="carousel-cell">
            <div class="testimonial">
                <p class="content">
                   "Meredith’s work ethic rivals the work ethic of <span>a big firm attorney or a fortune 500 CEO.</span>"
                </p>
                <p class="name">
                    - Puya N
                </p>
            </div>
        </div>
    </flickity>
    <Badges />
    <!-- ==============================================
        Main Content
        =============================================== -->
    <div id="contentwrap">
        <div class="row listing-title">
            <div class="col span3of3">
                <h2 class="title">Listings</h2>
            </div>
        </div>
        <div v-if="!$store.getters.getLoaded">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <Properties v-else v-bind:properties="homeProperties" v-bind:fromHomepage="true" v-bind:ribbonText="true" />
        <div style="margin-bottom:30px;text-align: center;position:relative;z-index:20">
            <router-link to="/sold" class="btn btndark">Past Sales</router-link>
        </div>
        <hr class="separator">
        <!-- Testimonials Section -->
        <Testimonials :numOfVideosShown="6"/>
        <div style="margin-bottom:20px;text-align: center;position:relative;z-index:20">
            <router-link to="/testimonials" class="btn btndark">More Testimonials</router-link>
        </div>
        <!--About Meredith-->
        <div id="about-meredith" class="row">
            <div class="col span3of3" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
                <h2 class="title" >About Meredith</h2>
            </div>
            <div class="inner">
                <div class="span1of3 left" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
                    <div class="meredith-image" ><img src="/images/meredith-full-new.png" alt="Meredith Portrait"></div>
                    <div class="meredith-image-mobile" ><img src="/images/headshot-left-1.jpg" alt="Meredith Portrait 2"></div>
                </div>
                <div class="span2of3 left" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
                    <p class="textleft">
                        Meredith approaches her business with passion, transparency, a tireless work ethic, and a tenacity that sets her apart from the rest.
                        She strives for <em><strong>real results </strong></em>for her clients - both tangible and intangible. 
                        The bottom line is critical; however, Meredith believes that so is the process and the client experience.
                    </p>
                    <p class="textleft">
                        Over the past 11 years, Meredith has become one of the top real estate agents in Los Angeles and has <em><strong>over $800 million in sales. </strong></em>
                        She is ranked in the top half of 1% of all Berkshire Hathaway Home Services agents (which includes over 50,000 agents both nationwide and abroad) as well as the top 1.5% of 1.6 million agents in the nation by Real Trends.
                        Meredith has appeared in notable and local news outlets such as the Los Angeles Times, Business Insider, LA Confidential and The Wall Street Journal.
                    </p>
                    <p class="textleft">
                        Navigating the waters of the highly competitive Los Angeles real estate market is no small task;
                        but since relocating from her native New York City, Meredith has made LA her own
                        She prides herself on a deep knowledge of LA's diverse neighborhoods, as well as the relationships she has built with clients and throughout the brokerage community.
                    </p>

                    <router-link to="/about" class="btn btndark" >Full Bio</router-link>
                    <div class="contact" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
                        <div class="right">
                            <p class="textright">
                                <a href="https://www.zillow.com/profile/Meredithschlosser/#reviews" target="_blank" aria-label="Go to Meredith Zillow Profile Page">
                                    <img class="zillow-star" src="/images/badge-zillow.png" alt="5 stars agent">
                                </a>
                            </p>
                        </div>
                        <div class="left">
                            <a href="mailto:meredithschlosser@gmail.com">meredithschlosser@gmail.com</a>
                            <p class="textleft">mobile: 310.923.5811</p>
                            <!--<p class="textleft">office: 310.691.7886</p>-->
                            <p class="textleft"><span>CalDRE: 01910316</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
    </div>
</div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import { VueperSlides, VueperSlide } from 'vueperslides'
import Flickity from 'vue-flickity';
import Properties from '../components/Properties.vue'
import Badges from '../components/Badges.vue'
import CoolLightBox from 'vue-cool-lightbox'
import Testimonials from '../components/Testimonials.vue'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
    name: "Home",
    components: {
        Spinner,
        VueperSlides,
        VueperSlide,
        Flickity,
        Properties,
        Badges,
        CoolLightBox,
        Testimonials
    },
    metaInfo: {
        title: 'Meredith Schlosser',
        meta: [
            {
                name: 'description',
                content: "Meredith's outgoing personality and tireless commitment to excellence allows her to form strong connections with her clients and to quickly identify their needs and goals. When sellers hire Meredith they don't just hire an 'agent', they hire someone that will be their advocate and fight for them every step of the way to make sure they get the highest price possible."
            },
            {
                name: 'keywords',
                content: "meredith schlosser, meredith, schlosser, aaroe, sally forster jones, los angeles, westside, Santa Monica, Sherman Oaks, Encino, Tarzana, Studio City, Woodland Hills, Los Angeles, Beverly Hills, Marina Del Rey, Bel Air, North Hollywood, Glendale, Calabasas, Westlake"
            },
            {
                name: 'author',
                content: "Meredith Schlosser"
            },
        ]
    },
    data(){
        return{
            showLoader: true,
            homeImages: [
                "/images/body/bg-001.webp",
                "/images/body/bg-002.webp",
                "/images/body/bg-003.webp",
                "/images/body/bg-004.webp",
                "/images/body/bg-005.webp",
                "/images/body/bg-006.webp",
                "/images/body/bg-007.webp",
            ],
            flickityOptions: {
                adaptiveHeight: true,
                wrapAround: true,
                prevNextButtons: true,
                groupCells: true,
                pageDots: false,
                draggable: false,
                arrowShape: { 
                    x0: 10,
                    x1: 55, y1: 50,
                    x2: 45, y2: 50,
                    x3: 0
                    }
            },
            testimonials: [
                {
                    thumb: '/images/testimonials/home-testimonial-video-button-jeanie.jpg',
                    src: 'https://vimeo.com/565592140'
                },
                {
                    thumb: '/images/testimonials/home-testimonial-video-button-closer.jpg',
                    src: 'https://vimeo.com/286090109'
                },
                {
                    thumb: '/images/testimonials/home-testimonial-video-button-november-2021.jpg',
                    src: 'https://vimeo.com/642334556'
                },
                {
                    thumb: '/images/testimonials/home-testimonial-video-button-2.jpg',
                    src: 'https://vimeo.com/321803601'
                },
                {
                    thumb: '/images/testimonials/home-testimonial-video-button-3.jpg',
                    src: 'https://vimeo.com/321803755'
                },
                {
                    thumb: '/images/testimonials/home-testimonial-video-button-jordan.jpg',
                    src: 'https://vimeo.com/286090283'
                },
                {
                    thumb: '/images/testimonials/home-testimonial-video-button-rory.jpg',
                    src: 'https://vimeo.com/581534377'
                },
                {
                    thumb: '/images/testimonials/home-testimonial-video-button-1.jpg',
                    src: 'https://vimeo.com/321803653'
                },
                {
                    thumb: '/images/testimonials/home-testimonial-video-button-the-best.jpg',
                    src: 'https://vimeo.com/286090427'
                },
            ],
            index: null,
        }
    },
    computed:{
        homeProperties(){
            let properties = this.$store.getters.properties;
            let propertiesHome = this.$store.getters.properties.filter((property) => {
                return property.Status === 'comingSoon' || property.Status === 'newListing' || (property.Status === '' || property.Status === null) || property.Status === 'inEscrow';
            })
            const totalLength = propertiesHome.length + 3 + (3 - propertiesHome.length % 3);
            properties = properties.sort((first, second) => {
                if (first.Status === 'comingSoon' && second.Status !== 'comingSoon'){
                    return -1;
                } else if (first.Status !== 'comingSoon' && second.Status === 'comingSoon'){
                    return 1;
                } else if (first.Status === 'newListing' && second.Status !== 'newListing'){
                    return -1;
                } else if (first.Status !== 'newListing' && second.Status === 'newListing'){
                    return 1;
                } else if (first.Status === null && second.Status !== null){
                    return -1;
                } else if (first.Status !== null && second.Status === null){
                    return 1;
                } else if (first.Status === 'inEscrow' && second.Status !== 'inEscrow'){
                    return -1;
                } else if (first.Status !== 'inEscrow' && second.Status === 'inEscrow'){
                    return 1;
                } else if (first.Status === 'sold' && second.Status !== 'sold'){
                    return 1;
                } else {
                    return  Number(second.Price) - Number(first.Price);
                }
            })
            properties = properties.slice(0, totalLength);
            return properties;
        }
    }
}
</script>

<style lang="scss" scoped>
#home{
    height: 100vh;
}
#slideshow{
    z-index: 20;
}

#ratings{
    hr{
        display: block;
        margin: 10px auto;
        height: 1px;
        border: 0;
        border-top: 1px solid #fff;
        width: 150px;
    }
}

.subtitle{
    margin-top: 0;
    margin-bottom: 20px;
    padding-bottom: 30px;
}

.zillow-star{
    width: 120px;
}

.title{
    margin: 40px 0;
}
</style>

<style lang="scss">
.vueperslides--parallax .vueperslides__track{
    transform: unset !important;
    height: 100%;
}
</style>
